import React, { useContext, useState, useEffect } from "react";
import { store } from "../store";
import { Row, Col } from 'antd';
import { Text, View, FlatList, Pressable, ScrollView, TextInput, ActivityIndicator } from 'react-native-web';
import { themeColor } from "../config/servervar";
import { ViewList, ViewDetail } from '../component/listdetail';
import axios from 'axios';
import { server_var } from "../config/servervar";
import { format } from 'date-fns'

const validateMobileNumber = (mobileNumber) => {
  if (mobileNumber) {
    var re = /^[0]\d{9}$/;
    return re.test(mobileNumber);
  }
  return false;
}

const LIST_DEFINE = [
  { id: 1, field1: 'id', flex: 0.1, textAlign: 'left', headerTitle: 'ID' },
  { id: 2, field1: 'mobileNumber', flex: 0.2, textAlign: 'left', headerTitle: 'เบอร์มือถือ' },
  { id: 3, field1: 'displayName', flex: 0.6, textAlign: 'left', headerTitle: 'ผู้ใช้งาน' },
];

const DETAIL_DEFINE = [
  {
    id: 1, fieldName: 'id', displayName: 'ID', newValue: 0, controlOption: {
      textInputStyle: { backgroundColor: 'lightgray' }, textInputProp: { editable: false }
    }
  },
  { id: 2, fieldName: 'mobileNumber', displayName: 'เบอร์มือถือ', newValue: '' },
  { id: 3, fieldName: 'firstName', displayName: 'ชื่อ', newValue: '' },
  { id: 4, fieldName: 'lastName', displayName: 'นามสกุล', newValue: '' },
  {
    id: 5, fieldName: 'address1', displayName: 'ที่อยู่', newValue: '',
    controlOption: {
      textInputProp: { multiline: false }
    }
  },
  {
    id: 6, fieldName: 'address2', displayName: 'อำเภอ', newValue: '',
    controlOption: {
      textInputProp: { multiline: false }
    }
  },
  {
    id: 7, fieldName: 'address3', displayName: 'จังหวัด', newValue: '',
    controlOption: {
      textInputProp: { multiline: false }
    }
  },
  { id: 8, fieldName: 'postCode', displayName: 'รหัสไปรษณีย์', newValue: '' },
  {
    id: 9, fieldName: 'memberCode', displayName: 'รหัสสมาชิก', newValue: '', controlOption: {
      textInputStyle: { backgroundColor: 'lightgray' }, textInputProp: { editable: false }
    }
  },
  {
    id: 10, fieldName: 'memberPoint', displayName: 'แต้ม', newValue: '0',
    controlOption: {
      textInputStyle: { backgroundColor: 'lightgray' }, textInputProp: { editable: false }
    }
  },
  {
    id: 11, fieldName: 'memberLevel', displayName: 'Level', newValue: 'Silver', controlOption: {
      textInputStyle: { backgroundColor: 'lightgray' }, textInputProp: { editable: false }
    }
  },
  {
    id: 12, fieldName: 'gender', displayName: 'เพศ', newValue: '', controlType: 'radio',
    controlOption: {
      list: [
        { id: 1, title: 'ชาย', value: '1' },
        { id: 2, title: 'หญิง', value: '2' },
        { id: 3, title: 'ไม่ระบุ', value: '3' },
      ]
    }
  },
  { id: 13, fieldName: 'birthDate', displayName: 'วันเดือนปีเกิด', newValue: '', controlType: 'dateinput' },
  { id: 14, fieldName: 'email', displayName: 'email', newValue: '' },
  { id: 15, fieldName: 'petInfo', displayName: 'สัตว์เลี้ยง', newValue: '' },
  { id: 16, fieldName: 'idCardNumber', displayName: 'เลขบัตรประชาชน', newValue: '' },
  { id: 17, fieldName: 'idCardExpiredDate', displayName: 'บัตรหมดอายุ', newValue: '', controlType: 'dateinput' },
  {
    id: 18, fieldName: 'refShopId', displayName: 'สร้างจากร้าน', newValue: 1, controlType: 'dropdown',
    controlOption: {
      visible: true,
      list: []
    }
  },
  {
    id: 19, fieldName: 'createdAt', displayName: 'วันเวลาที่กรอกข้อมูล', newValue: '', controlType: 'datetimeinput', controlOption: {
      textInputStyle: { backgroundColor: 'lightgray' }, textInputProp: { editable: false }
    }
  },
  { id: 20, fieldName: 'billNumber', displayName: 'หมายเลข Bill', newValue: '' },

];

const QUERY_NAME = 'users';
const QUERY_CON = { staffFlag: 0 };
const NEW_VALUE = { staffFlag: 0 };
// const QUERY_CON = {  };

export const Customer = () => {
  const [selectIndex, setSelectIndex] = useState(-1);
  const { globalState, dispatch } = useContext(store)
  const [queryParams, setQueryParams] = useState();
  const [waitFlag, setWaitFlag] = useState(false);
  const [detailDefine, setDetailDefine] = useState(DETAIL_DEFINE);
  const [shopList, setShopList] = useState([]);

  useEffect(() => {
    // console.log('hello user',globalState?.user?.shopId)
    loadShopDropDown();
  }, [])

  const beforeSavePress = async (data) => {
    setWaitFlag(true);
    console.log('before save');
    const errorList = await validateData(data);
    if (!(data?.birthDate)) {
      data['birthDate'] = null;
    }
    if (!(data?.idCardExpiredDate)) {
      data['idCardExpiredDate'] = null;
    }
    const options = {
      params: {
        firstName: data['firstName'],
        lastName: data['lastName']
      }
    };
    console.log('before save aaa');
    // if (data.id == 0) {
    //   console.log('before save bbb', data, errorList);
    //   setWaitFlag(false);
    //   return { data, errorList }
    // }
    //IMPORTANT 20250228
    if (data.id != 0) {
      console.log('before save bbb', data, errorList);
      setWaitFlag(false);
      return { data, errorList }
    }
    //IMPORTANT 20250228
    let res;
    console.log('before SAVE 2 ');
    try {
      res = await axios.get(`${server_var.server_api}/checkCustName`, options);
      console.log('before SAVE 3 ' + JSON.stringify(res));
    } catch (e) {
      console.log('error point1');
      setWaitFlag(false);
      return { data, errorList: [{ fieldName: 'firstName', errorFlag: true, errorMsg: 'ชื่อหรือนามสกุลมีปัญหา 1' }] }
    }
    if (res?.data?.returnCode == '0002') {
      console.log('error point2');
      setWaitFlag(false);
      return { data, errorList: [{ fieldName: 'firstName', errorFlag: true, errorMsg: 'มีข้อผิดพลาดเกิดขึ้น ไม่สามารถเชื่อมต่อกับระบบฐานข้อมูลสมาชิกได้' }] }
    }
    if (res?.data?.returnCode != '0000') {
      console.log('error point3');
      setWaitFlag(false);
      return { data, errorList: [{ fieldName: 'firstName', errorFlag: true, errorMsg: 'ชื่อหรือนามสกุลมีปัญหา อาจจะซ้ำกับที่มีอยู่แล้ว' }] }
    }

    console.log('res111:' + JSON.stringify(res.data));
    console.log('res data:' + JSON.stringify(data));
    if (data.id == 0) {
      const res2 = await loadBplusMember(data);
      if (res2?.errorList) {
        setWaitFlag(false);
        return { data, errorList: res2.errorList }
      }
    }
    setWaitFlag(false);
    return { data, errorList }
  }

  const loadBplusMember = async (data) => {
    const res2 = await fetch(`${server_var.server_api}/getExistMember?mobileNumber=${data?.mobileNumber}`, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    });
    const resJson = await res2.json();
    if (resJson?.error) {
      alert('การเชื่อมต่อมีปัญหา โปรดแจ้งเจ้าหน้าที่ Namping เพื่อตรวจสอบ');
      return { errorList: [{ fieldName: 'mobileNumber', errorFlag: true, errorMsg: 'การเชื่อมต่อมีปัญหา โปรดแจ้งเจ้าหน้าที่ Namping เพื่อตรวจสอบ' }] }
    }
    const retResult = resJson?.result?.recordsets?.[0]?.[0];
    if (retResult) {
      const retResult2 = Object.entries(retResult);
      // return retResult2
      return { errorList: [{ fieldName: 'mobileNumber', errorFlag: true, errorMsg: 'เบอร์นี้มีการสมัครสมาชิกแล้ว' }] }
    }
    return null;
  }


  const validateData = async (data) => {
    let tmpErrorList = [];
    // console.log('data' + JSON.stringify(data));
    // console.log('data1' + (parseInt(format(new Date(data.birthDate), 'yyyy')) + 1));
    // console.log('data2' + (parseInt(format(new Date(data.idCardExpiredDate), 'yyyy')) + 1));
    for (const item of ['mobileNumber', 'firstName', 'lastName', 'gender']) {
      if (!(data?.[item])) {
        tmpErrorList = [...tmpErrorList, { fieldName: item, errorFlag: true, errorMsg: 'ไม่สามารถเว้นไว้ได้' }]
      }
    }
    if (tmpErrorList.length > 0) {
      return tmpErrorList
    }
    if (!validateMobileNumber(data?.mobileNumber)) {
      tmpErrorList = [...tmpErrorList, { fieldName: 'mobileNumber', errorFlag: true, errorMsg: 'เบอร์มือถือไม่ถูกต้อง' }]
      return tmpErrorList
    }
    const res = await globalState.client.service('users').find({ query: { mobileNumber: data['mobileNumber'], id: { $ne: data.id } } })
    if (res.total > 0) {
      tmpErrorList = [...tmpErrorList, { fieldName: 'mobileNumber', errorFlag: true, errorMsg: 'เบอร์นี้ซ้ำ' }]
    }
    if (data.birthDate && !yearLessThanHundred(data.birthDate)) {
      tmpErrorList = [...tmpErrorList, { fieldName: 'birthDate', errorFlag: true, errorMsg: 'ไม่ถูกต้อง' }]
    }
    if (data.idCardExpiredDate && !yearLessThanHundred(data.idCardExpiredDate)) {
      tmpErrorList = [...tmpErrorList, { fieldName: 'idCardExpiredDate', errorFlag: true, errorMsg: 'ไม่ถูกต้อง' }]
    }
    return tmpErrorList
  }

  const onChangeQuery = (queryParams) => {
    setQueryParams(queryParams);
  }

  const downloadTxtFile = async () => {
    setWaitFlag(true);
    const res = await axios.post(`${server_var.server_api}/extractCustomer`, queryParams);
    setWaitFlag(false);
    if (res?.data?.csv) {
      console.log('texts', res.data.csv);
      const file = new Blob([res.data.csv], { type: 'text/plain' });
      const element = document.createElement("a");
      element.href = URL.createObjectURL(file);
      element.download = "customer" + Date.now() + ".csv";
      document.body.appendChild(element);
      element.click();
    } else {
      alert('มีข้อผิดพลาดเกิดขึ้น')
    }
    if (res.data.message) {
      alert(res.data.message);
    }
  }

  const loadShopDropDown = async () => {
    // const res = await globalState.client.service('shops').find({ query: { $limit: 100 } });
    const res = await globalState.client.service('shops').find({ query: { $select: ['id', 'shopName'], $limit: 100 } });
    setShopList(res.data.map(item => ({ key: item.id, label: item.shopName })));
    console.log('res', res);
    setDetailDefine(prev => {
      const tmp = prev.map(item2 => {
        if (item2.id != 18) {
          return item2
        } else {
          return {
            ...item2,
            newValue: globalState?.user?.shopId,
            controlOption: {
              ...item2.controlOption,
              list: res.data.map(item3 => ({ id: item3.id, title: item3.shopName, value: item3.id }))
            }
          }
        }
      })
      return tmp;
    })
  }

  return (
    <Row style={{ flex: 1, flexWrap: 'nowrap' }}>
      <Col span={12} style={{ height: '100vh', padding: 5 }}>
        <ViewList
          onPress={id => setSelectIndex(id)} listDefine={LIST_DEFINE} queryName={QUERY_NAME} queryCon={QUERY_CON}
          onChangeQuery={onChangeQuery}
          viewListOption={{
            topControlList: [
              ...globalState?.user?.roles == 'admin' ? [
                { id: 10, title: 'Export', type: 'button', onPress: () => downloadTxtFile() }
              ] : [],
              {
                id: 2, title: 'Search', type: 'multi_column_search',
                searchField: ['firstName', 'lastName', 'mobileNumber', 'memberCode', 'idCardNumber', 'billNumber'],
                placeholder: 'ชื่อ โทร บัตรปชช บัตรสมช บิล#',
                width: 220
              },
              {
                id: 3, title: 'Search', type: 'dropdown', searchField: 'refShopId', list: shopList,
              },
              { id: 4, title: '', type: 'daterange', searchField: 'createdAt', placeholder: ['วันเริ่มสร้าง', 'วันสิ้นสุด'] },
            ]
          }}
        />
      </Col>
      <Col span={12} style={{
        height: '100vh', padding: 5,
        borderWidth: 1, borderLeftStyle: 'solid', borderColor: 'gray'
      }}>
        <ViewDetail
          selectIndex={selectIndex} detailDefine={detailDefine} queryName={QUERY_NAME} onSavePress={(id) => {
            // setWaitFlag(false);
            console.log('hello on Save press')
            setSelectIndex(-1);
          }}
          newValue={NEW_VALUE}
          viewDetailOption={{ hideDelete: ['cashier', 'marketing'].includes(globalState?.user?.roles) }}
          beforeSavePress={beforeSavePress}
        />
      </Col>
      {waitFlag && (
        <View style={{
          position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center',
          alignItems: 'center', backgroundColor: 'white', opacity: 0.5
        }}>
          <ActivityIndicator size={'large'} color="black" />
        </View>
      )}
    </Row>
  )
}

function yearLessThanHundred(pDateStr) {
  if (!pDateStr) {
    return true;
  }
  let yearToCompare;
  const curYear = parseInt(format(new Date(), 'yyyy'));
  try {
    yearToCompare = parseInt(format(new Date(pDateStr), 'yyyy'));
  } catch (e) {
    console.log('error', e);
    return false;
  }
  const diffYear = curYear - yearToCompare;
  console.log('diffYear', diffYear);
  if (diffYear > 100 || diffYear < -100) {
    return false;
  }
  return true;
}

const delay = ms => new Promise(res => setTimeout(res, ms));